//const scrollTo = require('../components/scrollAnimate');
const Grid = require('../components/a11y/Grid');
const Feed = require('../components/a11y/Feed');
const toggleAccordionItem = require('./toggleAccordion').toggleAccordionItem;
const dataLayer = require('../dataLayer/dataLayer');
const waiNotifier = require('../components/waiNotify');
const breadcrumbs = require('../components/breadcrumbs');
let plpFeed;
const base = require('../product/base');
// TODO: a11y. after PLP update focus should stay on last focused element. Change to normal templates.

/**
 * Update DOM elements with Ajax results
 *
 * @param {object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @returns {undefined}
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {object} $results - jQuery DOM element
 * @returns {undefined}
 */
function handleAccordionState($results) {

    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        const activeDiv = $results.find(`.${$(this)[0].className.replace(/ /g, '.')}`);
        activeDiv.addClass('active');
        activeDiv.find('.js-refine-accordion-button').attr('aria-expanded', 'true');
    });

    $('.js-refinement-bar, .js-plp-banner, .js-open-filter-panel').toggleClass('m-hidden', $results.hasClass('m-no_results'));
    updateDom($results, '.b-refinements .js-refinements-container');
}


/**
 * Hides or shows the first three sections on mobile load
 */
function hideFirstThreeOnLoadMobile() {
    const media = require('../components/utils/media');
    var isDesktop = media.getBreakpoint() === 'large';
    const sectionElements = document.querySelectorAll('section.b-card_refinement');

    if (sectionElements) {
        sectionElements.forEach((section, index) => {
            if (index < 3) {
                if (!isDesktop) {
                    section.classList.remove('active');
                } else {
                    section.classList.add('active');
                }
            }
        });
    }
}

/**
 * Reinit Grid navigation for applied filters after HTML was updated
 * @returns {undefined}
 */
function reInitAppliedFilterNavigation() {
    const filterBarGrids = document.querySelectorAll('.js-filter-bar');
    const filterBarContainer = document.querySelector('.filterList');
    let hasAppliedFilters = false;

    if (filterBarGrids) {
        filterBarGrids.forEach(filterBarGrid => {
            const parentAttributeId = filterBarGrid.getAttribute('parent-attribute-id');
            const children = filterBarGrid.querySelectorAll('[data-parent-refinement-id]');
            const filterListDiv = document.querySelector(`.filterList[data-filterlist="${parentAttributeId}"]`);

            if (parentAttributeId && children && filterListDiv) {
                Array.from(children).forEach(child => {
                    const childParentRefinementId = child.getAttribute('data-parent-refinement-id');
                    if (childParentRefinementId && childParentRefinementId.toString() !== parentAttributeId.toString()) {
                        child.remove();
                    }
                });

                filterListDiv.appendChild(filterBarGrid);

                const appliedFiltersMain = filterBarGrid.querySelector('.b-applied_filters-main');
                if (appliedFiltersMain && appliedFiltersMain.children.length > 0 && filterBarContainer) {
                    filterBarGrid.style.display = 'block';
                    filterBarContainer.style.display = 'block';
                    hasAppliedFilters = true;
                }

                new Grid(filterBarGrid).init();
            }
        });
    }

    const clearCta = document.querySelector('.js-clear-cta');
    if (clearCta) {
        if (hasAppliedFilters) {
            clearCta.style.display = 'block';
        } else {
            clearCta.style.display = 'none';
        }
    }
}

/**
 * Reinit Grid navigation for breadcrumbs after HTML was updated
 * @returns {undefined}
 */
function reInitBreadcrumbsGrid() {
    const breadcrumbs = document.querySelector('.js-breadcrumbs [role=grid]');
    new Grid(breadcrumbs).init();
}

/**
 * Init and reinit Feed navigation for PLP after HTML was updated
 * @returns { void }
 */
function ariaFeed() {
    const beforeFeedFocus = document.querySelector('.js-filter-bar .js-reset-all-filters') ||
        document.querySelector('.js-product-sort select');
    const afterFeedFocus = document.querySelector('.js-show-more-btn') ||
        document.querySelector('.js-refine-accordion-button');

    if (plpFeed) {
        plpFeed.reinit(beforeFeedFocus, afterFeedFocus);
        return;
    }
    const feedEContainer = document.querySelector('.js-product-grid');
    if (feedEContainer) {
        plpFeed = new Feed(feedEContainer, beforeFeedFocus, afterFeedFocus).init();
    }
}

/*
 * Create notification for applied changes
 */
function waiNotify(message) {
    return waiNotifier(message || $('.js-product-grid').data('action-message-applied'));
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @returns {undefined}
 */
function parseResults(response) {
    const $results = $(response);
    const specialHandlers = {
        '.refinements': handleAccordionState
    };
    let selectors = [
        '.js-header-bar',
        '.js-results-popup',
        '.js-filter-bar',
        '.js-product-sort',
        '.js-page-title',
        '.js-product-grid',
        '.js-plp-top-banner',
        '.js-plp-footer-banner',
        '.js-plp-cat-banner',
        '.js-breadcrumbs'
    ];

    // Update DOM elements that do not require special handling
    selectors.forEach((selector) => {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach((selector) => {
        specialHandlers[selector]($results);
    });

    reInitBreadcrumbsGrid();
    ariaFeed();
    reInitAppliedFilterNavigation();

    const urlParams = new URLSearchParams(window.location.search);
    let numberOfClicksOnLoadMore = Number(urlParams.get('page'));

    if (numberOfClicksOnLoadMore > 1) {
        $('.js-show-more-btn').trigger('click', ['back', numberOfClicksOnLoadMore]);
    }
}

/**
 * Toggle overlay
 * @param {boolean} isLoading - toggle flag
 * @returns {undefined}
 */
function toggleBusy(isLoading) {
    if (isLoading) {
        $('.js-product-grid')
            .attr('aria-busy', 'true')
            .spinner().start();
    } else {
        $('.js-product-grid')
            .attr('aria-busy', 'false')
            .spinner().stop();
    }
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @returns {undefined}
 */
function getContent($element, $target) {
    const showMoreUrl = $element.data('url');
    toggleBusy(true);

    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success(response) {
            $target.append(response);
            toggleBusy(false);
        },
        error() {
            toggleBusy(false);
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @returns {undefined}
 */
function updateSortOptions(response) {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.js-grid-footer').data('sort-options').options;

    let items = $('.js-wishlist-items').data('items');
    if (items) {
        let arr = items.split(',');
        let icons = $('body').find('.wishlistTile').toArray();

        arr.forEach((item) => {
            icons.forEach((icon) => {
                if (item === $(icon).data('pid')) {
                    $(icon).addClass('b-product_wishlist-registered');
                }
            });
        });
    }

    sortOptions.forEach((option) => {
        $(`option.${option.id}`).val(option.url);
    });
}

/**
 * Close filter panel
 * @returns {undefined}
 */
function closeFilterPanel() {
    window.dialogManager.closeDialog();
}

/**
 * Open filter panel
 * @returns {undefined}
 */
function openFilterPanel() {
    window.dialogManager.openDialog('panel', '#refine-bar', $('.js-open-filter-panel')[0]);

    if ($('.js-remove-filter').length) {
        $('.js-reset-all-filters, .js-apply-all-filters').removeClass('m-disabled');
    }
}

/**
 * Expand element by id
 * @param {string} elementId - Element id wich need to expand
 */
function expand(elementId) {
    const el = $(elementId);
    if (!el.hasClass('m-expanded')) {
        const ariaControls = el.attr('aria-controls');
        el.parent().addClass('m-active').focus();
        el.addClass('m-expanded');
        el.next(`#${ariaControls}`).addClass('m-expanded');
    }
}

/**
 * Page scroll to the targer block
 * @param {object} event - Event object
 * @returns {undefined}
 */
function scrollToLetter(event) {
    event.preventDefault();
    const $this = $(this);
    const href = $this.attr('href');

    expand(href);

    $([document.documentElement, document.body]).animate({
        scrollTop: $(`${href}`).offset().top
    }, 400);
}

/**
 * Get Url parametr value
 * @param {String} url
 * @param {String} key
 * @returns {string} -value or ''
 */
function getUrlParameter(url, key) {
    var urlObject = url.split('?')[1].split('&').reduce((s, c) => {
        var t = c.split('=');
        s[t[0]] = t[1];
        return s;
    }, {});

    return (key in urlObject) ? urlObject[key] : '';
}

/**
 * Add selected refinements as parameters to url
 * @param {string} url - url
 * @returns {string} - url with added paremeters
 */
function addRefinementsToUrl(url) {
    const filter = {};
    const selectedAttrs = $('li.js-selected').parent('ul').toArray();
    const srule = $('.js-product-sort option:selected').data('id');
    const q = getUrlParameter(url, 'q');
    const cgid = getUrlParameter(url, 'cgid');
    const host = url.split('?')[0];

    if (srule) {
        filter.srule = srule;
    }

    if (q.length) {
        filter.q = q;
    }

    selectedAttrs.forEach((attr) => {
        const $el = $(attr);
        const attributeID = $el.data('attributeId');
        const selected = $el.find('li.js-selected').toArray();

        if (attributeID === 'price') {
            filter.price = {
                min: $(selected[0]).data('refinementValueFrom'),
                max: $(selected[0]).data('refinementValueTo')
            };
        } else {
            filter[attributeID] = selected.map(el => $(el).data('refinementValue'));
        }
    });

    if (cgid.length && !filter.category) {
        filter.category = [cgid];
    }

    let params = '';
    let index = 0;

    Object.keys(filter).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
            params += (params || host.includes('?') ? '&' : '?');
            if (key === 'srule' || key === 'q') {
                params += (`${key}=${filter[key]}`);
            } else if (key === 'category') {
                params += (`cgid=${filter[key][0]}`);
            } else if (key === 'price') {
                params += (`pmin=${filter[key].min}&pmax=${filter[key].max}`);
            } else {
                index++;
                params += (`prefn${index}=${key}&prefv${index}=${filter[key].join('|')}`);
            }
        }
    });


    return host + encodeURI(params);
}

/**
 * Mark refinement value as selected
 * @param {object} $selectedValue - selected element
 */
function markAsSelected($selectedValue) {
    const $element = $selectedValue.parent('li');
    const $headElement = $element.parent('ul');
    const isAttribute = $headElement.is('[data-is-attribute]');
    const isPreviouslySelected = $element.is('.js-selected');

    if (!isAttribute) {
        $element.siblings().removeClass('js-selected');
    }

    $element.toggleClass('js-selected', !isPreviouslySelected);
    $('.js-reset-all-filters, .js-apply-all-filters').removeClass('m-disabled');
}

function updateAvailabilityMsg() {
    $('body').trigger('tile:updateAvailabilityMsg');
}

function setUrlForMobile() {
    let url = new URL($('.permalink').val());
    url.searchParams.delete('start');
    url.searchParams.delete('sz');

    let mobileUrl = url.pathname + url.search;
    window.history.pushState({}, 'search', mobileUrl);
}

module.exports = {
    init() {
        if (window.location.hash) {
            expand(window.location.hash);
        }

        updateAvailabilityMsg();

        $('.js-contactstore-error, .js-emptystore-error').addClass('h-hidden');
        hideFirstThreeOnLoadMobile();
        reInitAppliedFilterNavigation();
    },

    scrollAlphabet() {
        // Display refinements bar when Menu icon clicked
        $('.js-brands_clp-click').on('click', scrollToLetter);
    },

    initBrandAccordion() {
        // brands accordion
        $('.js-brands_accordion').on('click', '[aria-controls]', function () {
            const el = $(this);
            if (el.hasClass('m-expanded')) {
                const ariaControls = el.attr('aria-controls');
                el.parent().removeClass('m-active');
                el.removeClass('m-expanded');
                el.next(`#${ariaControls}`).removeClass('m-expanded');
            } else {
                const ariaControls = el.attr('aria-controls');
                el.parent().addClass('m-active');
                el.addClass('m-expanded');
                el.next(`#${ariaControls}`).addClass('m-expanded');
            }
        });
    },

    openRefinements() {
        // Display refinements bar when Menu icon clicked
        $('.js-search-container').on('click', '.js-open-filter-panel', openFilterPanel);
    },

    closeRefinements() {
        // Refinements close button
        $('.js-search-container').on('click', '.js-close-filter-panel', closeFilterPanel);
    },

    feed() {
        ariaFeed();
    },

    accordion() {
        $('.js-search-container').on('click', '.js-refine-accordion-button', toggleAccordionItem);
    },

    sort() {
        // Handle sort order menu selection
        $('.js-search-container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            var $this = $(this);
            const media = require('../components/utils/media');
            const element = $('.js-product-grid');
            var isDesktop = media.getBreakpoint() === 'large';

            toggleBusy(true);
            $this.trigger('search:sort', this.value);
            const
                message = $this.data('action-message'),
                searchUrl = $this.find(':selected').data('searchUrl');

            window.history.pushState(undefined, '', searchUrl);

            $.ajax({
                url: this.value,
                method: 'GET',
                success(response) {
                    parseResults(response);
                    if (element.length && !isDesktop) {
                        $('html, body').animate({
                            scrollTop: element.offset().top - 300
                        }, 1000);
                    }
                    updateAvailabilityMsg();
                    toggleBusy(false);
                    waiNotify(message);
                },
                error() {
                    toggleBusy(false);
                    waiNotify($('.js-product-grid').data('action-message-canceled'));
                }
            });
        });
    },

    /**
     * Function to handle the "Show More" functionality for products
     */
    showMore() {
        const urlParams = new URLSearchParams(window.location.search);
        let numberOfClicksOnLoadM = Number(urlParams.get('page'));

        let counterOfClick = 1;
        let filterRemoved = 0;

        if (numberOfClicksOnLoadM > 1) {
            counterOfClick = numberOfClicksOnLoadM;
        }

        $('body').on('click', '.js-remove-filter, .js-reset-all-filters', function () {
            filterRemoved = 1;
        });

        // Show more products
        $('.js-search-container').on('click', '.js-show-more-btn', (e, requestFrom, numberOfClicksOnLoadMore) => {
            e.stopPropagation();
            e.preventDefault();

            const $button = $(e.target);
            const $loadMoreContainer = $('.js-show-more');
            const $lastFeedElement = $('.js-product-grid [role=article]').last();
            let showMoreUrl = $button.data('url');
            const urlParams = new URLSearchParams(window.location.search);

            toggleBusy(true);
            $loadMoreContainer.addClass('m-loading');
            if (requestFrom === 'back') {
                counterOfClick = numberOfClicksOnLoadMore;
                var indexOfStartParameter = showMoreUrl.indexOf('start');
                var partOfUrlBeforeStart = showMoreUrl.slice(0, indexOfStartParameter);
                var partOfUrlFromStart = showMoreUrl.slice(indexOfStartParameter);
                var startArrayParameters = partOfUrlFromStart.split('&')[0].split('=');
                var startParameterString = startArrayParameters.join('=');
                var currentStartValue = Number(startArrayParameters[1]);
                var stepForCurrentStart = Number(urlParams.get('page'));

                var newStartValue;

                if (filterRemoved === 1) {
                    newStartValue = Number(urlParams.get('page'));
                } else {
                    newStartValue = currentStartValue * (stepForCurrentStart - 1);
                }

                showMoreUrl = partOfUrlBeforeStart +
                    startArrayParameters[0] +
                    '=' +
                    newStartValue +
                    showMoreUrl.slice(indexOfStartParameter + startParameterString.length);
            }

            $(this).trigger('search:showMore', e);

            $.ajax({
                url: showMoreUrl,
                method: 'GET',
                success(response) {
                    $('.js-header-bar').replaceWith($(response).find('.js-header-bar')[0]);
                    $('.js-results-popup').replaceWith($(response).find('.js-results-popup'));
                    $('.js-grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    dataLayer.pushProductsInDataLayer();
                    ariaFeed();
                    $lastFeedElement.focus();
                    waiNotify($button.data('action-message'));

                    if (requestFrom !== 'back') {
                        counterOfClick += 1;
                    }

                    if (requestFrom === 'back' && filterRemoved === 1) {
                        counterOfClick = 1;
                    } else {
                        let url = new URL(window.location.href);
                        url.searchParams.set('page', String(counterOfClick));
                        window.history.pushState({}, '', url);
                    }

                    updateAvailabilityMsg();
                },
                error() {
                    waiNotify($('.js-product-grid').data('action-message-canceled'));
                }
            }).always(() => {
                toggleBusy(false);
                $loadMoreContainer.removeClass('m-loading');
            });
        });
    },

    /**
     * Handle refinement value selection and reset click
     */
    applyFilter() {
        // Handle refinement value selection and reset click
        $('body').on(
            'click',
            '.js-refinements-container li button,' +
            '.js-reset-all-filters, .js-remove-filter, .js-apply-all-filters, .js-search-a',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                const $this = $(this);
                const filterValue = $(e.target.closest('.b-card_refinement-list_item')).data('value');

                const isRefinementAttribute = $this.parent().is('[data-refinement-value]');
                if ($('.js-open-filter-panel').is(':visible') && isRefinementAttribute) {
                    markAsSelected($this);
                }

                // if mobile apply all button press get selected attributes and construct the request url
                const isApplyAll = $this.is('.js-apply-all-filters');
                if (isApplyAll) {
                    const url = addRefinementsToUrl($('.js-reset-all-filters').data('href'));
                    const categRefinementValue = $('.b-card_refinement-sublist .b-card_refinement-list_item.js-selected').data('refinement-value');

                    //if categRefinementValue exists update the url cgid parameter with the value from refinementValue
                    if (categRefinementValue && url.indexOf('cgid=') > -1) {
                        const cgid = url.split('cgid=')[1].split('&')[0];
                        const newUrl = url.replace(cgid, categRefinementValue);

                        $this.data('href', newUrl);
                    } else {
                        $this.data('href', url);
                    }
                }

                let refUrl = $this.data('href') || $this.attr('href');
                let searchUrl = $this.data('searchUrl');
                let removeFilter = $('.js-remove-filter, .js-reset-all-filters').is(e.target);

                window.history.pushState({}, 'search', searchUrl);
                // request for the update
                toggleBusy(true);
                $(this).trigger('search:filter', e);
                $.ajax({
                    url: refUrl,
                    data: {
                        page: $('.js-grid-footer').data('page-number'),
                        searchPage: $this.hasClass('js-search-a')
                    },
                    method: 'GET',
                    success(response) {
                        parseResults(response);
                        updateAvailabilityMsg();
                        toggleBusy(false);
                        waiNotify($this.data('action-message'));
                        if (isApplyAll || removeFilter) {
                            setUrlForMobile();
                        }
                        if ($this.parents('ul').data('attribute-id') === 'category') {
                            document.title = $this.text();
                        }
                        $('.js-page').data('querystring', $this.data('href').split('?')[1]);
                        $(`.b-card_refinement-list_item[data-value="${filterValue}"] > .b-checkbox`).focus();
                        breadcrumbs.init();

                        // Render only the selected values for the specific filter
                        const $filterContainer = $this.closest('.js-refinements-container');
                        const $filterValues = $filterContainer.find('.b-card_refinement-list_item');
                        $filterValues.each(function () {
                            const value = $(this).data('value');
                            if (value === filterValue) {
                                $(this).addClass('js-selected');
                            } else {
                                $(this).removeClass('js-selected');
                            }
                        });

                        const element = $('.js-product-grid');
                        if (element.length) {
                            $('html, body').animate({
                                scrollTop: element.offset().top - 300
                            }, 1000);
                        }
                    },
                    error() {
                        toggleBusy(false);
                        waiNotify($('.js-product-grid').data('action-message-canceled'));
                    }
                });
            }
        );
    },

    showContentTab() {
        // Display content results from the search
        $('.js-search-container').on('click', '.js-content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });
    },

    refinementValueFilter() {
        $('.js-refinements-container').on('input', '.js-refinement-block', (event) => {
            const filterText = $(event.target).val().toLowerCase();
            const valueItems = $(event.currentTarget).find('[data-value]').show();
            if (!filterText) {
                return;
            }
            valueItems.not(`[data-value^='${filterText}']`).hide();
        });
    },

    addToRegistry: base.addToRegistry
};
