/* eslint-disable jsdoc/require-returns */
const waiNotify = require('../waiNotify');

const selectors = {
    productDetail: '.js-product-detail',
    plpData: '.js-plp-data',
    productSetDetail: '.js-product-set-detail'
};

const $productContainer = $(selectors.productDetail).length ? $(selectors.productDetail) : $(selectors.plpData);
const notifications = {
    registry: $productContainer.data('action-message-registry'),
    canceled: $productContainer.data('action-message-canceled')
};

function setColorName() {
    const colorName = $('.js-color-button.m-selected').data('attr-value');
    if (colorName) {
        $('.js-selected-color').text(colorName);
    }
}

/**
 * sets default size value - its a crucial workaround for updating selected attribute after using brwoser 'back' button
 */
function setDefaultSize() {
    const option = $('.js-first_option');
    if (option) {
        attributeSelect(option.val(), $productContainer, null, true);
    } else {
        const $sizeSelect = $('.js-attribute-select');
        const $attributeResetOption = $sizeSelect.find('.js-reset_option');
        $attributeResetOption.val($attributeResetOption.val() + '#resetoption');
        const defaultValue = $sizeSelect.find('option[selected]').val() || $sizeSelect.val();

        // adding hash to relax attribute so jQuery will not determine it as first 'default' selected value
        $sizeSelect.val(defaultValue);
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {object} attributesMarkup - rendered markup with variation attributes
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product
 */
function updateAttrs(attributesMarkup, $productContainer) {
    var $attributesContainer = $productContainer.find('.js-product-attributes_container');
    $attributesContainer.html(attributesMarkup);
    setColorName();
}

function updateAvailability(response, $productContainer) {
    let availabilityValue = '';
    const availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = `<li class="b-product_status m-contact_store">${response.resources.info_selectforstock}</li>`;
    } else {
        availabilityMessages.forEach((message) => {
            if (message.text) {
                availabilityValue +=
                `<li class="b-product_status m-${message.status}">${message.text}</li>`;
            }
        });
    }
    $('body').trigger('product:updateFreeStorePickUpAvailability', {
        product: response.product,
        $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Detecting device
 * @returns {boolean} - True if mobile
 */
function isSmallViewPort() {
    return window.innerWidth < window.styleConstants.breakpoint.small;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {object} response - response from Ajax call
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    let product = response.product;
    if (product.variationAttributes) {
        updateAttrs(product.variationAttributesMarkup, $productContainer);
        updateAvailability(response, $productContainer);
    }
    let isShipToHomeAvailable = (!product.readyToOrder || product.custom.inStoreOnly ||
        !product.available || (product.comingsoon && !product.isComingSoonAvailable) ||
        !product.isShipToHomeAvailable ||
        (product.availableForInStorePickup && product.custom.fromStoreId)
    );
    let id = product.id;
    updateButtonsDisabledAttribute(!isShipToHomeAvailable, product.isVariationSelected, id);
}

/**
 * @param {boolean} homeAvailable - Product ID
 * @param {boolean} isVariationSelected - Product has a variation selected or dont have variations
 */
function updateButtonsDisabledAttribute(homeAvailable, isVariationSelected, id) {
    $('.js-home-delivery').attr('data-pid', id);
    $('.js-ISPU').attr('data-pid', id);
    $('.js-door-dash').attr('data-pid', id);

    if (homeAvailable && isVariationSelected) {
        $('.js-home-delivery').prop('disabled', false);
    } else {
        $('.js-home-delivery').prop('disabled', true);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for current product
 * @param {string} message - notification about applied changes
 * @param {number} setIndex - set selected default index or not
 */
function attributeSelect(selectedValueUrl, $productContainer, message, setIndex) {
    if (!selectedValueUrl) {
        return;
    }

    $('body').trigger('product:beforeAttributeSelect',
        { url: selectedValueUrl, container: $productContainer });
    const $plp = $('.modal-body');

    $plp.attr('aria-busy', true);
    $plp.spinner().start();

    $.ajax({
        url: selectedValueUrl + '&useshippinginfomodel="true"' + '&mobile=' + isSmallViewPort(),
        method: 'GET',
        success(data) {
            if (setIndex) {
                $(`.js-attribute-select option[value="${selectedValueUrl}"]`).prop('selected', true);
            }
            handleVariantResponse(data, $productContainer);

            $('body').trigger('product:afterAttributeSelect',
                { data, container: $productContainer });

            $plp.attr('aria-busy', false);
            $plp.spinner().stop();
        },
        error() {
            $plp.attr('aria-busy', false);
            $plp.spinner().stop();
            if (message) { // if it was intentional call and not under the hood changes. Ex: we trigger attributeSelect after addToBasket
                waiNotify(notifications.canceled);
            }
        }
    });
}

module.exports = {
    attributeSelect,
    colorAttribute() {
        $(document).on('click', '.js-color-button', (e) => {
            e.preventDefault();
            const $button = $(e.currentTarget);

            if ($button.attr('disabled') || $button.hasClass('m-unselectable')) {
                return;
            }
            let $productContainer = $button.closest('.add-to-cart-modal');
            if (!$productContainer.length) {
                $productContainer = $button.closest(selectors.plpData);
            }
            const notificationMessage = $button.data('attr-value') + ' ' + notifications.attr;
            attributeSelect($button.attr('data-url'), $productContainer, notificationMessage, 0);
        });
    },

    selectAttribute() {
        $(document).on('change', '.js-attribute-select', (e) => {
            e.preventDefault();
            const $attr = $(e.currentTarget);

            let $productContainer = $attr.closest('.add-to-cart-modal');
            if (!$productContainer.length) {
                $productContainer = $attr.closest(selectors.plpData);
            }

            const $selected = $attr.find('option:selected');
            const $storeElement = $('.js-ISPU');
            var storeId = $storeElement.attr('data-store-id');
            var url = $selected.val() + '&storeId=' + storeId;
            const notificationMessage = $selected.text() + ' ' + notifications.attr;

            attributeSelect(url, $productContainer, notificationMessage, false, $attr);
        });
    },
    setColorName,
    setDefaultSize,
    updateFreeStorePickUpAvailability() {
        $('body').on('product:updateFreeStorePickUpAvailability', (e, response) => {
            const isAvailableInPreferredStore = response.product.shippingInfo.availableInPreferredStore
            const isAvailableForInStorePickup = response.product.shippingInfo.isAvailableForInStorePickup;
            const isComingSoon = response.product.shippingInfo.comingsoon;
            const isComingSoonAvailable = response.product.shippingInfo.isComingSoonAvailable;

            var $selectedStore = $('.js-ISPU');
            var selectedStoreInfo = {
                ID: $selectedStore.attr('data-store-id'),
                name: $('.js-selected-store').text()
            }

            var $preferredStore = $('.js-selected-my-home-store');
            var preferredStoreInfo = JSON.parse($preferredStore.attr('data-store-info'));

            if (selectedStoreInfo.ID && selectedStoreInfo.name && preferredStoreInfo) {
                if (isAvailableInPreferredStore && isAvailableForInStorePickup && !(isComingSoon && !isComingSoonAvailable)) {
                    $('.js-ISPU').removeClass('m-disabled');
                    $('.js-preferred-store-error').addClass('h-hidden');
                    $('.js-contactstore-error').addClass('h-hidden');
                } else if (!isAvailableInPreferredStore && selectedStoreInfo.ID && isAvailableForInStorePickup) {
                    let preferredStoreError = $('.js-preferred-store').text().replace(preferredStoreInfo.name, selectedStoreInfo.name);
                    $('.js-preferred-store').text(preferredStoreError);
                    $('.js-ISPU').addClass('m-disabled');
                    $('.js-contactstore-error').addClass('h-hidden');
                    $('.js-preferred-store-error').removeClass('h-hidden');
                } else if (!isAvailableForInStorePickup) {
                    let itemNotInStore = $('.js-contactstore-error').text().replace('#', selectedStoreInfo.name);
                    $('.js-contactstore-error').text(itemNotInStore);
                    $('.js-ISPU').addClass('m-disabled');
                    $('.js-preferred-store-error').addClass('h-hidden');
                    $('.js-contactstore-error').removeClass('h-hidden');
                }
            } else if (selectedStoreInfo.ID && !(isComingSoon && !isComingSoonAvailable)) {
                $('.js-ISPU').removeClass('m-disabled');
            }
        });
    }
};
