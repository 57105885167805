'use strict';

const dataLayer = require('../dataLayer/dataLayer');

module.exports = {
    updateAvailabilityMsg: function () {
        $('body').on('tile:updateAvailabilityMsg', () => {
            const $tileContainer = $('.js-product_tile-inner.js-availability-msg-update');
            // eslint-disable-next-line complexity
            $tileContainer.each(function() {
                var $availabilityMsgBlock = $(this).find('.js-product_tile-availability_msg');
                var $shippingInfoBlock = $(this).find('.js-shipping-info');
                var $addToCartBtn = $(this).find('.js-add-to-cart-btn');
                $availabilityMsgBlock.removeClass().addClass('b-product_tile-availability_msg b-availability_msg js-product_tile-availability_msg');
                var isShipToHome = $shippingInfoBlock.find('.js-homeDelivery').hasClass('m-available');
                var isFreeISPU = $shippingInfoBlock.find('.js-freeInStorePickup').hasClass('m-available');
                var availabilityText = $shippingInfoBlock.data('availabilitytext');
                var availabilityType = $shippingInfoBlock.data('availabilitytype');
                var availableInStores = $shippingInfoBlock.data('availableinstores');
                var comingSoon = $shippingInfoBlock.data('comingsoon');
                var soldout = $shippingInfoBlock.data('soldout');
                var isComingSoonAvailable = $shippingInfoBlock.data('comingsoonavailable');

                $(this).find('.js-ISPU').attr('disabled', !isFreeISPU);
                $(this).find('.js-home-delivery').attr('disabled', !isShipToHome);
                $(this).find('.js-add-to-registry').attr('disabled', false);

                if (isShipToHome && availabilityType !== 'preorder' && availabilityType !== 'comingsoon') {
                    $availabilityMsgBlock.addClass('m-instock');
                } else if (isFreeISPU && !isShipToHome) {
                    $availabilityMsgBlock.addClass('m-pickuponly');
                    $availabilityMsgBlock.text(availabilityText);
                } else if (!isFreeISPU && !isShipToHome && !comingSoon) {
                    $availabilityMsgBlock.addClass('m-outofstock');
                    $availabilityMsgBlock.text(availabilityText);
                    if (availabilityType === 'instoreonly') {
                        $availabilityMsgBlock.removeClass('m-outofstock').addClass('m-instoreonly');
                    }
                    if (!(availabilityType === 'instoreonly' && availableInStores)) {
                        $addToCartBtn.attr('disabled', true);
                    }
                } else if (availabilityType === 'comingsoon' && comingSoon) {
                    $availabilityMsgBlock.addClass('m-comming_soon');
                    $availabilityMsgBlock.text(availabilityText);
                    if (!isComingSoonAvailable) {
                        $addToCartBtn.attr('disabled', true);
                    }
                } else if (availabilityType === 'soldout' && soldout) {
                    $availabilityMsgBlock.addClass('m-sold_out');
                    $availabilityMsgBlock.text(availabilityText);
                } else if (availabilityType === 'outofstock') {
                    $availabilityMsgBlock.text(availabilityText);
                    $addToCartBtn.attr('disabled', true);
                } else {
                    $availabilityMsgBlock.addClass('m-' + availabilityType);
                    $availabilityMsgBlock.text(availabilityText);
                }

                $(this).removeClass('js-availability-msg-update');
            });
        });
    },
    pdpLinkClick: function () {
        $('.js-pdp-link').on('click', (e) => {
            const $productInner = $(e.target).closest('.js-product-info');
            const productInfo = $productInner.data('productinfo');

            if (productInfo) {
                dataLayer.pushProductInDataLayer(productInfo);
            }
        });
    }
};
