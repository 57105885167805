/* eslint-disable jsdoc/require-returns */
const dataLayer = require('../dataLayer/dataLayer');
const cookieUtil = require('../utils/cookieUtil');
const waiNotify = require('../components/waiNotify');
const csrfHelper = require('../csrf');
const Tabs = require('../components/togglers/Tabs');

const selectors = {
    productDetail: '.js-product-detail',
    modalData: '.js-modal-data',
    plpData: '.js-plp-data',
    cart: '.js-cart',
    productSetDetail: '.js-product-set-detail',
    productBuyWith: '.js-is-buy-with .js-buy-related',
    productRelated: '.js-is-related .js-buy-related',
    colorAlert: '.js-variation-color-alert',
    sizeAlert: '.js-variation-size-alert'
};

const $productContainer = $(selectors.cart).length ? $(selectors.cart) : $(selectors.plpData).length ? $(selectors.plpData) : $(selectors.productDetail).length ? $(selectors.productDetail) : $(selectors.modalData);
const notifications = {
    qty: $productContainer.data('action-message-qty'),
    attr: $productContainer.data('action-message-attr'),
    unselected: $productContainer.data('action-message-unselected'),
    added: $productContainer.data('action-message-added'),
    registry: $productContainer.data('action-message-registry'),
    canceled: $productContainer.data('action-message-canceled')
};

/**
 *
 * @param {string} hash
 * @returns {object}
 */
function parseHash(hash = '') {
    return (hash.charAt(0) === '#' ? hash.substring(1) : hash).split('&').reduce((params, hk) => {
        const temp = hk.split('=');
        params[temp[0]] = decodeURIComponent(temp[1]);
        return params;
    }, {});
}

/**
 * Retrieves the relevant pid value
 * @param {JQuery<HTMLElement>} $el - DOM container for a given add to cart button
 * @returns {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    let pid;

    if ($('#editProductModal').hasClass('is-open') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('#quickViewModal').hasClass('is-open') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($(selectors.productSetDetail).length || $('.product-set').length) {
        pid = $($el).closest(selectors.productDetail).attr('data-pid');
    } else {
        pid = $(selectors.productDetail).not('.bundle-item').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {JQuery<HTMLElement>} $el - DOM container for the relevant quantity
 * @returns {JQuery<HTMLElement>} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    let stickyFooter = $el.parents('.js-sticky-footer');
    let qtyValue = stickyFooter.length > 0 ? stickyFooter.find('.js-quantity-select') : $('.js-quantity-select');
    return $el && $('.set-items').length
        ? $($el).closest(selectors.productDetail).find('.js-quantity-select')
        : qtyValue;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {JQuery<HTMLElement>} $el - DOM container for the relevant quantity
 * @returns {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

function setColorName() {
    const colorName = $('.js-color-button.m-selected').data('attr-value');
    if (colorName) {
        $('.js-selected-color').text(colorName);
    }
}

/**
 *
 * @param {JQuery<HTMLElement>} $addToRegistryButton
 * @param {string} pids
 * @param {string} pid
 * @param {string} quantity
 * @param {number} [registryNumber]
 */
function addToRegistry($addToRegistryButton, pids, pid, quantity, registryNumber) {
    $.spinner().start();

    const data = {
        pids,
        pid,
        quantity,
        registryNumber
    };

    data[csrfHelper.getCsrfToken().tokenName] = csrfHelper.getCsrfToken().token;

    return $.ajax({
        url: $addToRegistryButton.data('addUrl'),
        method: 'POST',
        data: data
    }).then(response => {
        if (response.redirectUrl) {
            location.assign(response.redirectUrl);
        } else if (response.success) {
            const $addingProducts = $addToRegistryButton
                .closest('.js-recommendation-block')
                .find('.js-buy-related-registry:checked, .js-buy-related-checkbox-main')
                .filter(':not(.js-registry-warn)');

            const warnPids = $addToRegistryButton
                .closest('.js-recommendation-block')
                .find('.js-buy-related-registry:checked, .js-buy-related-checkbox-main')
                .filter('.js-registry-warn')
                .toArray();

            if (warnPids.length) {
                let $modalContainer = $('.js-warn-registry-container, #warnRegistryContainer');
                $modalContainer.remove();
                $modalContainer = $($('.js-warn-registry-template').html());
                $('body').append($modalContainer);
                $modalContainer.attr('id', 'warnRegistryContainer');
                $modalContainer.find('.js-lowstock-items')
                    .text(
                        warnPids.map(warnPid => $(warnPid).data('productName')).join(', ')
                    )
                    .end()
                    .find('.js-registry-id')
                    .text(response.registryNumber)
                    .end()
                    .find('.js-registry-footer')
                    .removeAttr('hidden')
                    .end()
                    .find('.js-link')
                    .attr('href', response.registryLink);


                window.dialogManager.openDialog('modal', '#warnRegistryContainer', document.activeElement);

            } else {
                let $modalContainer = $('.js-add-registry-container');
                $modalContainer.remove();

                $modalContainer = $($('.js-add-registry-confirmation').html());
                $('body').append($modalContainer);
                $modalContainer.attr('id', 'addRegistryContainer');

                $modalContainer.find('.js-product-name').text('"' + response.productName + '"');

                if ($addingProducts.length) {
                    $modalContainer.find('.js-product-name')
                        .addClass('b-confirm_gift_modal-list').html(
                            $addingProducts.toArray().map(
                                product => `<div>${$(product).data('productName')}</div>`
                            ).join('')
                        );
                }

                $modalContainer.find('.js-registry-number').text('#' + response.registryNumber);
                $modalContainer.find('.js-registry-link').attr('href', response.registryLink);
                window.dialogManager.openDialog('modal', '#addRegistryContainer', document.activeElement);
            }


            waiNotify(notifications.registry);
        } else if (!response.success) {
            waiNotify(notifications.canceled);
        }
    }).always(() => {
        $.spinner().stop();
    });
}

/**
 * sets default size value - its a crucial workaround for updating selected attribute after using brwoser 'back' button
 */
function setDefaultSize() {
    if ($productContainer.hasClass('js-cart')) {
        return;
    }
    const option = $('.js-first_option');
    if (option) {
        attributeSelect(option.val(), $productContainer, null, true);
    } else {
        const $sizeSelect = $('.js-attribute-select');
        const $attributeResetOption = $sizeSelect.find('.js-reset_option');
        $attributeResetOption.val($attributeResetOption.val() + '#resetoption');
        const defaultValue = $sizeSelect.find('option[selected]').val() || $sizeSelect.val();

        // adding hash to relax attribute so jQuery will not determine it as first 'default' selected value
        $sizeSelect.val(defaultValue);
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {object} attributesMarkup - rendered markup with variation attributes
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product
 * @param {JQuery<HTMLElement>} eventTarget - DOM element for a given product
 */
function updateAttrs(attributesMarkup, $productContainer, eventTarget) {
    if ($productContainer.hasClass('js-cart')) {
        eventTarget.html(attributesMarkup);
        setColorName();
    } else {
        var $attributesContainer = $productContainer.find('.js-product-attributes_container');
        $attributesContainer.html(attributesMarkup);
        setColorName();
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    let availabilityValue = '';
    const availabilityMessages = response.product.availability.messages;

    if (!response.product.readyToOrder) {
        availabilityValue = `<li class="b-product_status m-contact_store">${response.resources.info_selectforstock}</li>`;
    } else {
        availabilityMessages.forEach((message) => {
            if (message.text) {
                availabilityValue +=
                `<li class="b-product_status m-${message.status}">${message.text}</li>`;
            }
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

function updateTabs() {
    document.querySelectorAll('.js-related-items-tabs')
        .forEach(tablist => new Tabs(tablist).init());
}
/**
 * Generates html for promotions section
 *
 * @param {Array} promotions - list of promotions
 * @returns {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    let html = '';

    promotions.forEach((promotion) => {
        html += `<div class="callout" title="${promotion.details}">${promotion.calloutMsg}</div>`;
    });

    return html;
}

/**
 * Generates html for product attributes section
 *
 * @param {Array} attributes - list of attributes
 * @returns {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    let html = '';

    attributes.forEach((attributeGroup) => {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach((attribute) => {
                html += `<div class="attribute-values">${attribute.label}: ${
                    attribute.value}</div>`;
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach((option) => {
        const $optionEl = $productContainer.find(`.product-option[data-option-id*="${option.id}"]`);
        option.values.forEach((value) => {
            const valueEl = $optionEl.find(`option[data-value-id*="${value.id}"]`);
            valueEl.val(value.url);
        });
    });
}

/**
 * Format a money string
 *
 * @param {Number} amount
 * @param {String} currencyCode
 *
 * @returns {String}
 */
function formatMoney(amount, currencyCode) {
    const currentLocale = document.documentElement.lang;

    return amount.toLocaleString(currentLocale, {
        style: 'currency',
        currency: currencyCode
    }).replace(/\s*/g, '').replace('CA', '');
}

/**
 * Updates recommendations total
 * @param {JQuery<HTMLElement>} $recommendationBlock - DOM element for a given product.
 */
function updateBuyWithTotalPrice($recommendationBlock) {
    const $totalPrice = $recommendationBlock.find('.js-price-total-value');
    const $priceValues = $recommendationBlock.find('.js-price-value');
    let total = 0;
    const currencyCode = $('[data-currencycode]').data('currencycode');

    $priceValues.each((index, element) => {
        total += Number($(element).data('price'));
    });

    $totalPrice.text(formatMoney(total, currencyCode));
    $totalPrice.data('price', total);
}


/**
 * Detecting device
 * @returns {boolean} - True if mobile
 */
function isSmallViewPort() {
    return window.innerWidth < window.styleConstants.breakpoint.small;
}

/**
 * Showing mobile or desktop recomm block
 */
function showRecommendations() {
    if (isSmallViewPort()) {
        $('.js-recomm-items-mobile').show();
        $('.js-recomm-items').remove();
    } else {
        $('.js-recomm-items').show();
    }
}

/**
 * Updates recommendations
 * @param {Object} response - Ajax response object after an
 *                       attribute value has been [de]selected
 */
function updateRecommendationsBlock(response) {
    /**
     * @type {JQuery<HTMLElement>}
     */
    let $recommendationBlock = $('.js-recomm-items');
    if (!$recommendationBlock.is(':visible') && $('.js-recomm-items-mobile').length) {
        $recommendationBlock = $('.js-recomm-items-mobile');
    }
    if (response.product.recommendationTabs) {
        $recommendationBlock.html(response.product.recommendationTabs);
        $recommendationBlock.show();
    } else {
        $recommendationBlock.hide();
    }
    if ($recommendationBlock.length && $recommendationBlock.is(':visible')) {
        const $buyWithBlock = $recommendationBlock.find('.js-buy-with');
        $buyWithBlock.find('.js-main-product-id').text(response.product.id);
        $buyWithBlock.find('.js-main-product-name').text(response.product.productName);
        const $mainProductPrice = $buyWithBlock.find('.js-main-product-price');
        $mainProductPrice.data('price', response.product.price.sales.value);
        $mainProductPrice.text(response.product.price.sales.formatted);
        updateBuyWithTotalPrice($buyWithBlock);

        const $realtedBlock = $recommendationBlock.find('.js-related');
        if ($realtedBlock.length) {
            $realtedBlock.find('.js-add-to-cart-buy-with').removeAttr('disabled');
            $realtedBlock.find('.js-baby-registry').removeAttr('disabled');
        }
    }
}

/**
 * Updates instorepickup block
 * @param {Object} response - Ajax response object after an attribute value has been [de]selected
 */
function updateInstorePickupBlock(response) {
    $('body').trigger('product:updateInStorePickup', response.product);
}

/**
 * Updates shipping info block
 * @param {Object} response - Ajax response object after an attribute value has been [de]selected
 */
function updateShippingBlock(response) {
    const $shippingInfoContainer = $('.js-pdp-shipping-info');
    if ($shippingInfoContainer.length > 0) {
        $shippingInfoContainer.empty().append(response.product.storesAvailabilityMarkup);
    }
}

/**
 * Updates tabs content
 * @param {Object} response - Ajax response object after an attribute value has been [de]selected
 */
function updateDescriptionTabs(response) {
    const fragment = $.parseHTML(response.product.descriptionTabs);
    ['.js-description-content', '.js-info-content', '.js-howto-content'].forEach(sectionSelector => {
        $(sectionSelector).html($(fragment).find(sectionSelector).html());
    });
}

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    let carousel = $productContainer.find('.js-product-images-slick');
    let carouselThumbs = $productContainer.find('.js-product-thumbnails');
    let mediumBreakPoints = window.styleConstants.breakpoint.medium;
    let smallBreakPoints = window.styleConstants.breakpoint.small;

    $(carousel).slick('unslick').empty();
    $(carouselThumbs).slick('unslick').empty();

    for (var i = 0; i < imgs.length; i++) {
        $('<div class="b-product_carousel-image js-zoom ' + (i == 0 ? 'm-active' : '') + '"><img src="' + imgs[i].url + '" class="b-product_carousel-image_item js-product-image" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carousel));
        $('<div class="b-product_carousel-thumb ' + (i == 0 ? 'm-active' : '') + '"><img src="' + imgs[i].url + '" class="b-product_carousel-thumb_item js-product-thumbnail" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" title="' + imgs[i].title + '" itemprop="image" /></div>').appendTo($(carouselThumbs));
    }

    carousel.slick({
        slidesToShow: 1,
        dots: false,
        asNavFor: '.js-product-thumbnails',
        swipe: false,
        responsive: [
            {
                breakpoint: mediumBreakPoints,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    swipe: true
                }
            }
        ]
    });

    carouselThumbs.slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.js-product-images-slick',
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        responsive: [
            {
                breakpoint: mediumBreakPoints,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });

    if ($(window).width() > mediumBreakPoints) {
        $('.js-zoom').css('display', 'block').parent().zoom({ magnify: 1.5 });
    }
}

/**
 * @param {boolean} isRegisterable
 */
function handleDisplayRegistryButton(isRegisterable) {
    let $addToRegistryButton = $('.js-add-to-baby-pdp > .js-add-to-registry');
    if (isRegisterable) {
        if ($addToRegistryButton.hasClass('h-hidden')) {
            $addToRegistryButton.removeClass('h-hidden');
        }
    } else {
        if (!$addToRegistryButton.hasClass('h-hidden')) {
            $addToRegistryButton.addClass('h-hidden');
        }
    }
}

/**
 * @param {boolean} homeAvailable - Product ID
 * @param {boolean} forInStorePickup - Product ID
 * @param {boolean} isVariant - Product ID
 */
function updateButtonsDisabledAttribute(homeAvailable, forInStorePickup, isVariant, id) {
    $('.js-home-delivery').attr('data-pid', id);
    $('.js-ISPU').attr('data-pid', id);
    $('.js-door-dash').attr('data-pid', id);

    if (homeAvailable && isVariant) {
        if ($('.js-home-delivery').attr('disabled')) {
            $('.js-home-delivery').removeAttr('disabled');
            $('.js-home-delivery-unavailable-message').addClass('h-hidden');
        }
    } else if (!$('.js-home-delivery').attr('disabled')) {
        $('.js-home-delivery').prop('disabled', true);
        $('.js-home-delivery-unavailable-message').removeClass('h-hidden');
    }

    if (forInStorePickup && isVariant) {
        if ($('.js-ISPU').hasClass('m-disabled')) {
            $('.js-ISPU').removeClass('m-disabled');
        }
    } else if (!$('.js-ISPU').hasClass('m-disabled')) {
        $('.js-ISPU').addClass('m-disabled');
    }
}

/**
 * Update the wishList state
 * @param {object} response - response from Ajax call
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product.
 */
function updateWishList(response, $productContainer) {
    var $wishList = $productContainer.find('a.js-wishList-pdp');
    if ($wishList.length > 0) {
        for (let i = 0; i < $wishList.length; i++) {
            let $element = $($wishList[i]);
            $element.attr('data-pid', response.product.id);

            let wishListItems = $('.js-wishlist-items').attr('data-items');
            if (wishListItems) {
                let wishListItemsArray = wishListItems.split(',');
                if (wishListItemsArray.indexOf(response.product.id) > -1) {
                    $element.addClass('b-product_wishlist-registered');
                } else {
                    $element.removeClass('b-product_wishlist-registered');
                }
            }
        }
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {object} response - response from Ajax call
 * @param {object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {object[]} response.product.variationAttributes - Product attributes
 * @param {object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for a given product.
 * @param {JQuery<HTMLElement>} eventTarget - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, eventTarget) {
    const isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    let isVariant;
    eventTarget = eventTarget ? eventTarget : $('.js-attribute-select');

    var containerAttr = eventTarget.closest('.js-product-attributes_container');
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributesMarkup, $productContainer, containerAttr);
        isVariant = response.product.productType === 'variant';
        showRecommendations();
        updateRecommendationsBlock(response);
        updateInstorePickupBlock(response);
        updateShippingBlock(response);
        updateDescriptionTabs(response);
        updateWishList(response, $productContainer);

        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images.large;
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        if ($productContainer.hasClass('js-cart')) {
            const containerAttrParent = containerAttr.closest('.b-product_details-variation');
            const priceDiv = containerAttrParent.siblings('.b-product_cart-price');
            const $priceSelector = $('.js-price', priceDiv).length
                ? $('.js-price', priceDiv)
                : $('.js-price');
            $priceSelector.replaceWith(response.product.price.html);
        } else {
            const $priceSelector = $('.js-prices .js-price', $productContainer).length
                ? $('.js-prices .js-price', $productContainer)
                : $('.js-prices .js-price');
            $priceSelector.replaceWith(response.product.price.html);
        }
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);

    let product = response.product;
    let isShipToHomeAvailable = (!product.readyToOrder || product.custom.inStoreOnly ||
        !product.available || (product.comingsoon && !product.isComingSoonAvailable) ||
        !product.isShipToHomeAvailable ||
        (product.availableForInStorePickup && product.custom.fromStoreId)
    );
    let isAvailableForInStorePickup = product.availableForInStorePickup;
    let id = product.id;
    let isRegisterable = product.custom.registerable;

    updateButtonsDisabledAttribute(!isShipToHomeAvailable, isAvailableForInStorePickup, isVariant, id);
    handleDisplayRegistryButton(isRegisterable);
    updateBabyRegistryPDPButton(product.isMaster, product.isVariant, id);

    if (isChoiceOfBonusProducts) {
        const $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: product, $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', { product: product, $productContainer })
            .trigger('product:statusUpdate', product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {object} productResponse - response product object
 * @param {JQuery<HTMLElement>} $productContainer - DOM container for a given product
 */
function updateQuantities(productResponse, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        const optionsHtml = productResponse.quantities.map((quantity) => {
            const selected = quantity.selected ? ' selected ' : '';
            return `<option value="${quantity.value}"  data-url="${quantity.url}"${
                selected}>${quantity.value}</option>`;
        }).join('');

        $('.js-orderlimit_message')
            .toggleClass('h-hidden', !productResponse.orderLimitMessage)
            .empty()
            .html(productResponse.orderLimitMessage);

        getQuantitySelector($productContainer)
            .toggleClass('m-disabled', !!productResponse.custom.inStoreOnly)
            .attr('disabled', !!productResponse.custom.inStoreOnly)
            .empty()
            .html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for current product
 * @param {string} message - notification about applied changes
 * @param {number} setIndex - set selected default index or not
 */
function attributeSelect(selectedValueUrl, $productContainer, message, setIndex, eventTarget) {
    if (!selectedValueUrl) {
        return;
    }

    $('body').trigger('product:beforeAttributeSelect',
        { url: selectedValueUrl, container: $productContainer });
    const $pdp = $('.js-pdp-container');

    $pdp.attr('aria-busy', true);
    $pdp.spinner().start();
    const cartPage = $productContainer.hasClass('js-cart');
    const url = selectedValueUrl + '&mobile=' + isSmallViewPort() + '&cartPage=' + cartPage;

    $.ajax({
        url: url,
        method: 'GET',
        success(data) {
            handleVariantResponse(data, $productContainer, eventTarget);
            updateOptions(data.product.options, $productContainer);
            updateQuantities(data.product, $productContainer);
            updateAvailability(data, $productContainer);
            updateTabs();

            $('body').trigger('product:afterAttributeSelect',
                { data, container: $productContainer });

            if (setIndex) {
                $(`.js-attribute-select option[value="${selectedValueUrl}"]`).prop('selected', true);
            }

            let {isVariationSelected, id} = data.product;
            updatePDPSpinButton($productContainer, isVariationSelected, data.product);

            $pdp.attr('aria-busy', false);
            $pdp.spinner().stop();
            if (message) {
                waiNotify(message);
            }
        },
        error() {
            $pdp.attr('aria-busy', false);
            $pdp.spinner().stop();
            if (message) { // if it was intentional call and not under the hood changes. Ex: we trigger attributeSelect after addToBasket
                waiNotify(notifications.canceled);
            }
        }
    });
}

function updateBabyRegistryPDPButton(isMaster, isVariant, pid) {
    let $addToRegistryButton = $('.js-add-to-baby-pdp > .js-add-to-registry');
    let $colorVariantSelector = $('.js-color-button');
    let $sizeVariantSelector = $('.js-size-options');
    
    var disableButton = false;
    if ($addToRegistryButton.length > 0 && ($addToRegistryButton.data('isMaster') || $addToRegistryButton.data('isVariant'))) {
        if ($colorVariantSelector.length > 0 && !$colorVariantSelector.hasClass('m-selected')) {
            $(selectors.colorAlert).removeClass(window.styleConstants.hideClass);
            disableButton = true;
        } else {
            if (!$(selectors.colorAlert).hasClass(window.styleConstants.hideClass)) {
                $(selectors.colorAlert).addClass(window.styleConstants.hideClass);
            }
        }

        if ($sizeVariantSelector.length > 0 && $('.js-size-options:selected').length === 0) {
            $(selectors.sizeAlert).removeClass(window.styleConstants.hideClass);
            disableButton = true;
        } else {
            if (!$(selectors.sizeAlert).hasClass(window.styleConstants.hideClass)) {
                $(selectors.sizeAlert).addClass(window.styleConstants.hideClass);
            }
        }

        $addToRegistryButton.prop('disabled', disableButton);
    }

    $addToRegistryButton.data('isMaster', isMaster);
    $addToRegistryButton.data('isVariant', isVariant);
    $addToRegistryButton.data('pid', pid);

}

/**
 * Enable or disable Spin button on PDP if the product is or isn't a variation
 *
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for current product
 * @param {boolean} isVariationSelected - true if is variation selected
 * @param {object} product - Product object
 */
function updatePDPSpinButton($productContainer, isVariationSelected, product) {
    const $spinButton = $productContainer.find('.js-pdp-quantity');

    if ($spinButton.length) {
        let {readyToOrder, inStoreOnly, availability, isShipToHomeAvailable, availableForInStorePickup, availableInStores, maxOrderQuantity} = product;

        if (!isVariationSelected || !readyToOrder || inStoreOnly || availability.type === 'outofstock' || !(isShipToHomeAvailable || availableForInStorePickup || availableInStores)) {
            $spinButton.prop('disabled', true);
        } else {
            $spinButton.prop('disabled', false);
        }

        if (maxOrderQuantity) {
            $spinButton.attr('aria-valuemax', maxOrderQuantity);
        }

        if ($spinButton[0].spinbutton) {
            $spinButton[0].spinbutton.update();
        }
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @returns {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @returns {object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    let bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const htmlString = `${'<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="'}${data.maxBonusItems}"` +
        `data-UUID="${data.uuid}"` +
        `data-pliUUID="${data.pliUUID}"` +
        `data-addToCartUrl="${data.addToCartUrl}"` +
        'data-pageStart="0"' +
        `data-pageSize="${data.pageSize}"` +
        `data-moreURL="${data.showProductsUrlRuleBased}"` +
        `data-bonusChoiceRuleBased="${data.bonusChoiceRuleBased}">` +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `    <span class="">${data.labels.selectprods}</span>` +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success(response) {
            const parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        }
    });
}

let registryAgeConfirmation = false;
/**
 * Shows age modal dialog
*/
function showAgeModal() {
    window.dialogManager.openDialog('modal', '#ageModal', $('.js-add-to-cart')[0]);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {JQuery<HTMLElement>} $trigger - DOM element for diffrent CTA buttons
 */
function handlePostCartAdd(response, $trigger) {
    const messageType = response.error ? 'alert-danger' : 'alert-success';
    const $productCta = $trigger.closest('.js-product-cta-btns');
    const $ctaWrapper = $productCta.length ? $productCta : $trigger.closest('.js-recommendation-cta');
    const $quantityError = $ctaWrapper.find('.js-stock-error');
    const $restrictionError = $ctaWrapper.find('.js-restriction-error');

    $quantityError
        .addClass('h-hidden')
        .empty();

    $restrictionError
        .addClass('h-hidden')
        .empty();
    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (typeof (response.message) === 'object' && response.message.quantityMessage) {
        $quantityError
            .removeClass('h-hidden')
            .text(response.message.quantityMessage);
    } else if (typeof(response.message) === 'object' && response.message.restrictionMessage) {
        $restrictionError
            .removeClass('h-hidden')
            .text(response.message.restrictionMessage);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            `<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`
        );

        setTimeout(() => {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
    if (!response.isRestriction && response.quantityTotal === 0) {
        $('#quantity_select').attr('disabled', true);
        $trigger.attr('disabled', true);
        $('.product-availability .availability-msg li').removeClass('m-in_stock').addClass('m-out_of_stock').text(response.message.availableMessage);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @returns {string} - List of selected bundle product item ID's
 */
function getChildProducts() {
    const childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : '[]';
}

/**
 * Retrieve product options
 *
 * @param {JQuery<HTMLElement>} $productContainer - DOM element for current product
 * @returns {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    const options = $productContainer
        .find('.product-option')
        .map(function () {
            const $elOption = $(this).find('.options-select');
            const urlValue = $elOption.val();
            const selectedValueId = $elOption.find(`option[value="${urlValue}"]`)
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Add to cart action
 * @param {JQuery<HTMLElement>} $trigger - DOM element depending from triggered CTA button
 */

function _addToCart($trigger) {
    let addToCartUrl;
    let pidsObj;
    let setPids;
    const $this = $(this);

    $('body').trigger('product:beforeAddToCart', this);

    if (($('.set-items').length && $this.hasClass('add-to-cart-global')) ||
        ($('.js-buy-related').length && $this.hasClass('js-add-to-cart-buy-with'))) {

        setPids = [];
        let $productBlock;

        if ($this.hasClass('add-to-cart-global')) {
            $productBlock = $(selectors.productDetail);
        } else {
            const $recommendationParent = $this.closest('.js-recommendation-block');
            $productBlock = $this.hasClass('js-is-buy-with')
                ? $recommendationParent.find(selectors.productBuyWith)
                : $recommendationParent.find(selectors.productRelated);
        }

        $productBlock.each((index, element) => {
            const $element = $(element);
            const $relatedCheckbox = $element.find('.js-buy-related-checkbox');
            const $quantitySelect = $element.find('.js-quantity-select');
            if ((!$element.hasClass('product-set-detail') && !$element.hasClass('js-buy-related')) ||
                ($relatedCheckbox.length && $relatedCheckbox.is(':checked'))) {
                setPids.push({
                    pid: $element.find('.product-id').text(),
                    qty: $quantitySelect.length ? $quantitySelect.val() : 1,
                    options: getOptions($element)
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
    }
    var pid = getPidValue($this);

    if ($this.hasClass('js-registry-warn-add-to-cart')) {
        const $recommendationParent = $('.js-recommendation-block:first');
        let $productBlock = $recommendationParent.find(selectors.productBuyWith);
        setPids = [];
        $productBlock.each((index, element) => {
            const $element = $(element);
            const $relatedCheckbox = $element.find('.js-buy-related-checkbox');
            const $quantitySelect = $element.find('.js-quantity-select');

            if ((!$element.hasClass('product-set-detail') && !$element.hasClass('js-buy-related')) ||
                        ($relatedCheckbox.hasClass('js-registry-warn') && $relatedCheckbox.length && $relatedCheckbox.is(':checked'))) {
                setPids.push({
                    pid: $element.find('.product-id').text(),
                    qty: $quantitySelect.length ? $quantitySelect.val() : 1,
                    options: getOptions($element)
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
        pid = '';
    }


    let $productContainer = $this.closest(selectors.productDetail);
    if (!$productContainer.length) {
        $productContainer = $this.closest('.quick-view-dialog').find(selectors.productDetail);
    }

    // eslint-disable-next-line prefer-const
    addToCartUrl = getAddToCartUrl();

    const form = {
        pid,
        pidsObj,
        childProducts: getChildProducts(),
        quantity: getQuantitySelected($this)
    };
    const hashParams = parseHash(location.hash);
    if (hashParams.registryNumber) {
        form.registryNumber = hashParams.registryNumber;
        form.registryOwner = hashParams.registryOwner;
    }

    if (!$('.bundle-item').length) {
        form.options = getOptions($productContainer);
    }

    $(this).trigger('updateAddToCartFormData', form);

    if (addToCartUrl) {
        const tokenObject = csrfHelper.getCsrfToken();
        $.ajax({
            url: addToCartUrl + `?${tokenObject.tokenName}=${tokenObject.token}`,
            method: 'POST',
            data: form,
            success(data) {
                handlePostCartAdd(data, $trigger);
                dataLayer.addToCartDataLayerEventInit(data.quantity);
                $('body').trigger('product:afterAddToCart', data);
                // Selecting default variant in case of successful call

                if (!data.error) {
                    waiNotify(notifications.added);
                    attributeSelect($('.js-default-url').val(), $productContainer, null, true);
                }
            },
            error(err) {
                csrfHelper.checkCsrfTokenInResponse(err);
                waiNotify(notifications.canceled);
            }
        });
    }
}

function _addSetToCart($trigger) {
    let addToCartUrl;
    let pidsObj;
    let setPids;
    const $this = $(this);

    $('body').trigger('product:beforeAddToCart', this);
    var pid = getPidValue($this);

    if ($('.set-items').length && $this.hasClass('add-to-cart-set')) {

        setPids = [];
        let $productBlock = $('.set-items');
        $productBlock.each((index, element) => {
            const $element = $(element);
            const $quantitySelect = $element.find('.js-quantity-select');
            if (!pid || pid === $element.attr('data-pid')) {
                setPids.push({
                    pid: $element.attr('data-pid'),
                    qty: $quantitySelect.length ? $quantitySelect.val() : 1
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
    }

    let $productContainer = $this.closest(selectors.productSetDetail);
    if (!$productContainer.length) {
        $productContainer = $this.closest('.quick-view-dialog').find(selectors.productDetail);
    }
    // eslint-disable-next-line prefer-const
    addToCartUrl = getAddToCartUrl();

    const form = {
        pid,
        pidsObj,
        childProducts: getChildProducts(),
        quantity: getQuantitySelected($this)
    };

    $(this).trigger('updateAddToCartFormData', form);

    if (addToCartUrl) {
        const tokenObject = csrfHelper.getCsrfToken();
        $.ajax({
            url: addToCartUrl + `?${tokenObject.tokenName}=${tokenObject.token}`,
            method: 'POST',
            data: form,
            success(data) {
                handlePostCartAdd(data, $trigger);
                dataLayer.addToCartDataLayerEventInit(data.quantity);
                $('body').trigger('product:afterAddToCart', data);
                // Selecting default variant in case of successful call

                if (!data.error) {
                    waiNotify(notifications.added);
                    attributeSelect($('.js-default-url').val(), $productContainer, null, true);
                }
            },
            error(err) {
                csrfHelper.checkCsrfTokenInResponse(err);
                waiNotify(notifications.canceled);
            }
        });
    }
}

module.exports = {
    attributeSelect,
    methods: {
        editBonusProducts(data) {
            chooseBonusProducts(data);
        }
    },

    focusChooseBonusProductModal() {
        $('body')[0].addEventListener('shown.bs.modal', (event) => {
            if (event.target !== document.getElementById('#chooseBonusProductModal')) {
                return;
            }
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },

    onClosingChooseBonusProductModal() {
        $('body')[0].addEventListener('dialog:closed', (event) => {
            if (event.target !== document.getElementById('#chooseBonusProductModal')) {
                return;
            }
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },

    colorAttribute() {
        $(document).on('click', '.js-color-button', (e) => {
            e.preventDefault();
            const $button = $(e.currentTarget);

            if ($button.attr('disabled') || $button.hasClass('m-unselectable')) {
                return;
            }
            let $buttonColor = $button.closest('.set-item');
            if (!$buttonColor.length) {
                $buttonColor = $button.closest($productContainer);
            }

            let notificationMessage = '';

            if (!$button.hasClass('m-selected')) {
                notificationMessage = $button.attr('aria-label') + ' ' + notifications.attr;
            } else {
                notificationMessage = $button.attr('aria-label') + ' ' + notifications.unselected;
            }

            attributeSelect($button.data('url'), $productContainer, notificationMessage, false, $button);
        });
    },

    selectAttribute() {
        $(document).on('change', '.js-attribute-select', (e) => {
            e.preventDefault();
            const $attr = $(e.currentTarget);

            let $select = $attr.closest('.set-item');
            if (!$select.length) {
                $select = $attr.closest($productContainer);
            }

            const $selected = $attr.find('option:selected');
            const notificationMessage = $selected[0].label === 'Please Select' ? '' : $selected[0].label + ' ' + notifications.attr;

            const $storeElement = $('.js-selected-store-with-inventory');
            var storeId = $storeElement.attr('data-store-id');
            var url = $selected.val() + '&storeId=' + storeId;

            attributeSelect(url, $productContainer, notificationMessage, false, $attr);
        });
    },

    availability() {
        $('body').on('spinbutton:change', (e) => {
            e.preventDefault();
            const $select = $('.js-quantity-select').eq(0);
            const $selected = $select.find('option:selected');
            let url = $selected.data('url').toString().split('&').filter(element => !element.includes('quantity')).join('&');
            url = url + '&quantity=' + $select.val();
            let $productContainer = $select.closest(selectors.productDetail);
            const searchPID = $productContainer.attr('data-pid');
            const selectorPrefix = '.js-product-detail[data-pid="' + searchPID + '"]';

            if ($(selectorPrefix + ' .js-selected-store-with-inventory').is(':visible')) {
                return;
            }

            if (!$productContainer.length) {
                $productContainer = $select.closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                const notificationMessage = notifications.qty.toString();

                attributeSelect(url, $productContainer, notificationMessage, false);
            }
        });
    },

    addToCart() {
        $(document).on('click', 'button.js-add-to-cart, button.add-to-cart-global, button.js-add-to-cart-buy-with, button.js-registry-warn-add-to-cart',
            function (e) {
                const $this = $(this);

                if (!e.isTrigger && $this.data('esrb') && !cookieUtil.get('esrb')) {
                    registryAgeConfirmation = false;
                    showAgeModal();
                } else {
                    _addToCart.call(this, $(e.target));
                }
            });
    },
    addSetToCart() {
        $(document).on('click', 'button.js-add-to-cart-set',
            function (e) {
                const $this = $(this);
                if (!e.isTrigger && $this.data('esrb') && !cookieUtil.get('esrb')) {
                    registryAgeConfirmation = false;
                    showAgeModal();
                } else {
                    _addSetToCart.call(this, $(e.target));
                }
            });
    },
    addToRegistry() {
        const showWarnDialog = function (e) {
            e.preventDefault();
            const $addToRegistryButton = $(this);
            if ($addToRegistryButton.data('isMaster')) {
                if ($addToRegistryButton.data('pdpUrl')) {
                    location.assign($addToRegistryButton.data('pdpUrl'));
                    return;
                }
            } else {
                let $modalContainer = $('.js-warn-registry-container, #warnRegistryContainer');
                $modalContainer.remove();

                $modalContainer = $($('.js-warn-registry-template').html());
                $('body').append($modalContainer);
                $modalContainer.attr('id', 'warnRegistryContainer');

                $modalContainer.one('click', '.js-registry-warn-add-to-cart', () => {
                    $('button.js-add-to-cart').first().trigger('click');
                });

                window.dialogManager.openDialog('modal', '#warnRegistryContainer', document.activeElement);
            }
        };

        /**
        * Event handler for when a user clicks on the 'Add to Baby Registry' warning button
        * @param {Event} e - the click event
        */
        $(document).on('click', '.js-add-to-registry-warn', function (e) {
            if ($('.js-product-detail').length) {
                showWarnDialog.call(this, e);
            } else {
                $('.js-add-to-cart-btn.js-registry-warn-add-to-cart-cta').removeClass('js-registry-warn-add-to-cart-cta');
                $(this).closest(".b-product_tile-caption").find('.js-add-to-cart-btn').addClass('js-registry-warn-add-to-cart-cta');
                showWarnDialog.call(this, e);
            }
        });

        /**
        * Triggers a click event on the element with class '.js-registry-warn-add-to-cart'
        */
        $(document).on('click', '.js-registry-warn-add-to-cart-plp', function () {
            setTimeout(function () {
                $('.js-registry-warn-add-to-cart-cta').trigger('click');
            }, 0);
        });

        const $loggedInUser = $('.js-logged-in-user');
        $loggedInUser.each((_, dataElement) => {
            if (Number($(dataElement).data('registriesAmount')) === 0) {
                $('.js-add-to-registry').each((_, button) => {
                    const $button = $(button);
                    $button.text($button.data('alternateText'));
                });
            }
        });
        $(document).on('click', '.js-add-to-registry', function (e) {
            const $addToRegistryButton = $(this);

            $(document).trigger('baby-registry.clicked');
            
            if ($addToRegistryButton.data('isMaster')) {
                if ($addToRegistryButton.data('pdpUrl')) {
                    location.assign($addToRegistryButton.data('pdpUrl'));
                    return;
                }
            } else {
                if (!e.isTrigger && $addToRegistryButton.data('esrb') && !cookieUtil.get('esrb')) {
                    registryAgeConfirmation = true;
                    showAgeModal();
                } else {
                    const pid = getPidValue($addToRegistryButton) || $addToRegistryButton.data('pid');
                    const pids = $(e.target)
                        .closest('.js-recommendation-block')
                        .find('.js-buy-related-registry:checked')
                        .filter(':not(.js-registry-warn)')
                        .toArray()
                        .map(element => $(element).attr('name'))
                        .join(',') || '';
    
                    const quantity = getQuantitySelected($addToRegistryButton) || 1;
                    const registriesAmount = Number($loggedInUser.data('registriesAmount'));
    
                    if (
                        $addToRegistryButton.hasClass('js-buy-together') &&
                        !pids &&
                        $('.js-buy-related-checkbox-main').hasClass('js-registry-warn')
                    ) {
                        let $modalContainer = $('.js-warn-registry-container, #warnRegistryContainer');
                        $modalContainer.remove();
                        $modalContainer = $($('.js-warn-registry-templateBuyWith').html());
                        $('body').append($modalContainer);
                        $modalContainer.attr('id', 'warnRegistryContainer');
                        window.dialogManager.openDialog('modal', '#warnRegistryContainer', document.activeElement);
                    } else if ($loggedInUser.length) {
                        $.spinner().start();
    
                        if (registriesAmount === 0) {
                            location.assign($loggedInUser.data('createRegistryUrl') + `?pid=${pid}&quantity=${quantity}&pids=${pids}`);
                        } else if (registriesAmount === 1) {
                            addToRegistry($addToRegistryButton, pids, pid, quantity);
                        } else {
                            $.get($loggedInUser.data('selectUrl'))
                                .then(response => {
                                    let $modalContainer = $('.js-select-registry-container');
                                    $modalContainer.remove();
    
                                    $modalContainer = $(response);
                                    $('body').append($modalContainer);
                                    $modalContainer.attr('id', 'selectRegistryContainer');
    
                                    $modalContainer.find('.js-create-new-registry').one('click', () => {
                                        $.spinner().start();
    
                                        location.assign($loggedInUser.data('createRegistryUrl') + `&pid=${pid}&quantity=${quantity}&pids=${pids}`);
                                    });
                                    $modalContainer.find('.js-add-to-selected').one('click', () => {
                                        window.dialogManager.closeDialog();
                                        addToRegistry($addToRegistryButton, pids, pid, quantity, $modalContainer.find('.js-customer-registry:checked').val());
                                    });
    
                                    $modalContainer.one('click', '.js-show-more-registries', function () {
                                        $(this).addClass('h-hidden');
                                        $modalContainer.find('.js-more-registries').removeClass('h-hidden');
                                    });
    
                                    window.dialogManager.openDialog('modal', '#selectRegistryContainer', document.activeElement);
                                    //waiNotify(notifications.registry);
                                })
                                .always(() => {
                                    $.spinner().stop();
                                });
                        }
                    } else {
                        location.assign($('.js-login-link').data('createRegistryUrl') + `&pid=${pid}&quantity=${quantity}&pids=${pids}`);
                    }
                }
            }
        });
    },
    selectBonusProduct() {
        $(document).on('click', '.select-bonus-product', function () {
            const $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            const pid = $(this).data('pid');
            const maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            const submittedQty = parseInt($(this).parents('.choice-of-bonus-product')
                .find('.bonus-quantity-select').val(), 10);
            let totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            const optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
            const valueId = $(this).parents('.choice-of-bonus-product')
                .find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                // eslint-disable-next-line prefer-template
                const selectedBonusProductHtml = '' +
                    '<div class="selected-pid row" ' +
                    'data-pid="' + pid + '"' +
                    'data-qty="' + submittedQty + '"' +
                    'data-optionID="' + (optionID || '') + '"' +
                    'data-option-selected-value="' + (valueId || '') + '"' +
                    '>' +
                    '<div class="col-sm-11 col-9 bonus-product-name" >' +
                    $choiceOfBonusProduct.find('.product-name').html() +
                    '</div>' +
                    '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>' +
                    '</div>';

                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct() {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            const $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            let count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection() {
        $('body').on('bonusproduct:updateSelectButton', (e, response) => {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            const pid = response.product.id;
            $('button.select-bonus-product').data('pid', pid);
        });
    },
    showMoreBonusProducts() {
        $(document).on('click', '.show-more-bonus-products', function () {
            const url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url,
                method: 'GET',
                success(html) {
                    const parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error() {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart() {
        $(document).on('click', '.add-bonus-products', () => {
            const $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            let queryString = '?pids=';
            const url = $('.choose-bonus-product-dialog').data('addtocarturl');
            const pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                const qtyOption = parseInt($(this)
                    .data('qty'), 10);

                let option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = `${queryString}&uuid=${$('.choose-bonus-product-dialog').data('uuid')}`;
            queryString = `${queryString}&pliuuid=${$('.choose-bonus-product-dialog').data('pliuuid')}`;
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success(data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('.error-choice-of-bonus-products')
                            .html(data.errorMessage);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                            );
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            `<div class="alert alert-success add-to-basket-alert text-center" role="alert">
                                ${data.msgSuccess}
                            </div>`
                        );
                        setTimeout(() => {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                window.location.reload();
                            }
                        }, 3000);
                    }
                },
                error() {
                    $.spinner().stop();
                }
            });
        });
    },
    calculateBuyWithTotalPrice() {
        $(document).on('click', '.js-buy-related-checkbox', function () {
            const $totalPrice = $('.js-price-total-value');
            const $this = $(this);
            const itemPrice = Number($this.parent().find('.js-price-value').data('price'));
            const newTotal = Number($totalPrice.data('price'));
            const currencyCode = $('[data-currencycode]').data('currencycode');

            if ($this.is(':checked') && itemPrice && newTotal) {
                $totalPrice.text(formatMoney(newTotal + itemPrice, currencyCode));
                $totalPrice.data('price', newTotal + itemPrice);
            } else if (itemPrice && newTotal) {
                $totalPrice.text(formatMoney(newTotal - itemPrice, currencyCode));
                $totalPrice.data('price', newTotal - itemPrice);
            }

            const $relatedBlock = $this.parents('.js-related');
            if ($relatedBlock.length) {
                if (!$relatedBlock.find('.js-buy-related-checkbox:checked').length) {
                    $relatedBlock.find('.js-add-to-cart-buy-with, .js-baby-registry').attr('disabled', 'disabled');
                } else {
                    $relatedBlock.find('.js-add-to-cart-buy-with, .js-baby-registry').removeAttr('disabled');
                }
            }
        });
    },
    updateRecommendationBlock() {
        $(document).ready(() => {
            const $recommBlock = $('.js-recomm-items');
            const isSimpleProduct = !($('select[class*="js-attribute-select"]').length && $('[data-attr="color"]').length);
            if ($recommBlock.length && (($('select[class*="js-attribute-select"], .options-select:selected').length &&
                $('[data-attr="color"] button.m-selected').length > 0) || isSimpleProduct)) {
                showRecommendations();
            }
        });
    },
    confirmEsrb() {
        $(document).on('click', '.js-age-esrb-confirm', () => {
            window.dialogManager.closeDialog();
            cookieUtil.set('esrb', true);

            $(registryAgeConfirmation ? '.js-add-to-registry' : '.js-add-to-cart').trigger('click');
        }).on('click', '.js-age-modal-close', () => {
            window.dialogManager.closeDialog('#showAgeModal');
        });
    },

    getPidValue,
    getQuantitySelected,
    setColorName,
    setDefaultSize
};
