'use strict';

var cookieUtil = {
    get: function(name) {
        var parts = ('; ' + document.cookie).split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    },

    set: function(name, value, expires) {
        var expiresDate = (expires !== undefined) ? '; Expires=' + expires : '';
        document.cookie = name + '= ' + value + '; path=/' + expiresDate;
    },

    remove: function(name) {
        document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    isEnabled: function() {
        var cookie = document.cookie;
        document.cookie = 'check=1';
        if (cookie === document.cookie) {
            return false;
        }

        document.cookie = 'check=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
        return true;
    }
};

module.exports = cookieUtil;
