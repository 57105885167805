function getCsrfToken() {
    const $jsCsrfTokenSelector = $('.js-csrf-token');
    return {
        token: $jsCsrfTokenSelector.val(),
        tokenName: $jsCsrfTokenSelector.data('name')
    };
}

function checkCsrfTokenInResponse(response) {
    if (response.responseJSON.csrfError) {
        window.location.href = response.responseJSON.redirectUrl;
    }
}

module.exports = {
    getCsrfToken: getCsrfToken,
    checkCsrfTokenInResponse: checkCsrfTokenInResponse
};
