module.exports = function () {
    const sizes = ['xs'];

    sizes.forEach((size) => {
        const selector = `.collapsible-${size} .h-title`;
        $('body').on('click', selector, function (e) {
            e.preventDefault();
            $(this).parents(`.collapsible-${size}`).toggleClass('m-active');
            $(this).toggleClass('m-expanded');
            $(this).next().toggleClass('m-expanded');

            $(this).attr('aria-expanded',
                $(this).parents(`.collapsible-${size}`).hasClass('m-active'));
        });
    });
};
