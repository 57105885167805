'use strict';
var base = require('../components/dialogs/base');

const storeLocator = require('../storeLocator/storeSelector');
const validation = require('../components/clientSideValidation');
const waiNotify = require('../components/waiNotify');
const dataLayer = require('../dataLayer/dataLayer');
const csrfHelper = require('../csrf');
const wishlist = require('../wishlist/wishlist');
const cookieUtil = require('../utils/cookieUtil');
const storeSelectors = {
    storeResultsContainer: '.js-nearest-store-results',
    selectedStore: '.js-selected-store-with-inventory',
    selectedStoreTitle: '.js-selected_store_title',
    contactStoreError: '.js-contactstore-error',
    emptyStoreError: '.js-emptystore-error',
    preferredStoreError: '.js-preferred-store-error'
};

function fillModalElement(pid, quantity, selectedPostalCode, selectedRadius) {
    const requestData = {
        products: `${pid}:${quantity}`
    };

    if (selectedRadius) {
        requestData.radius = selectedRadius;
    }

    if (selectedPostalCode) {
        requestData.postalCode = selectedPostalCode;
    }

    $('.js-store-inventory-body')
        .attr('aria-busy', true)
        .addClass('m-animated')
        .spinner().start(true);

    $.ajax({
        url: $('.js-btn-get-in-store-inventory').data('action-url'),
        data: requestData,
        method: 'GET',
        success: (response) => {
            $('.js-store-inventory-body').empty()
                .attr('aria-busy', false)
                .removeClass('m-animated')
                .spinner().stop();
            $('.js-store-inventory-body').html(response.storesResultsHtml);
            validation.init();
            storeLocator.search();
            storeLocator.selectStore();
            storeLocator.updateSelectStoreButton();
            storeLocator.expandStoresEvent('#inStoreInventoryModal');

            $('.btn-storelocator-search').attr('data-search-pid', pid);

            if (selectedRadius) {
                $('#radius').val(selectedRadius);
            }

            if (selectedPostalCode) {
                $('#store-postal-code').val(selectedPostalCode);
            }

            if (!$('.results').data('has-results')) {
                $('.store-locator-no-results').show();
            }
        },
        error: () => {}
    });
}

function initConfirmationClosePopup() {
    $(document.body).on('click.closeConfimation', (e) => {
        const $target = $(e.target);
        const $modal = $target.closest('.js-store-inventory-modal');
        const $modalContent = $target.closest('.js-store-inventory-modal-content');

        if ($modal.length && ($target.closest('.js-modal-close-btn').length || !$modalContent.length)) {
            window.dialogManager.closeDialog();
        }

        if ($target.closest('.js-close-confirm-select').length) {
            window.dialogManager.closeDialog();

            const $selectBtn = $('.js-store-inventory-modal').find('.js-select-store');

            if ($selectBtn.length && $selectBtn.hasClass('js-store-has-been-selected') && !$('.js-selected-store-with-inventory').length) {
                $selectBtn.trigger('click');
            }

            $(document.body).off('click.closeConfimation');
        }

        if ($target.closest('.js-cancel-close').length) {
            window.dialogManager.closeDialog();
        }
    });
}

function getModalHtmlElement() {
    if ($('#inStoreInventoryModal').length !== 0) {
        $('#inStoreInventoryModal').remove();
    }

    const htmlTemplate = $('#isp-modal-template').html();

    $('body').append(htmlTemplate);
    window.dialogManager.openDialog('panel', '#inStoreInventoryModal', $('.js-btn-get-in-store-inventory')[0]);
    initConfirmationClosePopup();
}

function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.js-wishlistItemCardsData').data('public-view');
    var listUUID = $('.js-wishlistItemCardsData').data('uuid');
    var url = $('.js-wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done((data) => {
        $('.js-wishlistItemCards').empty();
        $('body .js-wishlistItemCards').append(data);
        require('../../js/components/forms/Spinbutton')();
        wishlist.init();
        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(() => {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

function removeFromWishList(isWishlist, url) {
    if (isWishlist) {
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {},
            success: () => {
                var pageNumber =
                    $('.js-wishlistItemCardsData').data('page-number') - 1;
                renderNewPageOfItems(pageNumber, false, '');
            },
            error: () => {
                $.spinner().stop();
            }
        });
    }
}

function addToCart(e) {
    let $this = $(e);
    let modal = $('body').find('#AddToCartModal');
    let $productInner = $this.closest('.js-product-info');
    let productInfo = $productInner.data('productinfo');
    let $modalContainer = $($this.siblings('.js-add-to-cart-modal').html());

    if (modal.length !== 0) {
        modal.remove();
    }

    $('body').append($modalContainer);
    let modalShippingBlock = $this.parent('.b-product_tile-caption, .b-wishlist_item-inner').find('.js-shipping-info');
    let isWishList = $this.parent('.b-wishlist_item-inner');

    let preferredStore = $('.js-selected-my-home-store').attr('data-store-info');
    let dataStore = preferredStore ? JSON.parse(preferredStore) : false;

    let isAvailableInPrefStore = modalShippingBlock.data('availabilityinpreferredstore');
    let isShipToHome = modalShippingBlock.find('.js-homeDelivery').hasClass('m-available');
    let isAvailableForInStorePickup = modalShippingBlock.data('availableforinstorepickup');
    let isComingSoon = modalShippingBlock.data('comingsoon');
    let isComingSoonAvailable = modalShippingBlock.data('comingsoonavailable');

    $(storeSelectors.emptyStoreError).addClass('h-hidden');
    $(storeSelectors.contactStoreError).addClass('h-hidden');
    $(storeSelectors.preferredStoreError).addClass('h-hidden');
    $('.js-ISPU').addClass('m-disabled');

    if (dataStore) {
        if (isAvailableInPrefStore && isAvailableForInStorePickup && !(isComingSoon && !isComingSoonAvailable)) {
            $('.js-ISPU').removeClass('m-disabled');
            $('.js-preferred-store-error').addClass('h-hidden');
            $('.js-contactstore-error').addClass('h-hidden');
        } else if (!isAvailableInPrefStore && dataStore.ID && isAvailableForInStorePickup) {
            let preferredStoreError = $('.js-preferred-store').text().replace('#', dataStore.name);
            $('.js-preferred-store').text(preferredStoreError);
            $('.js-ISPU').addClass('m-disabled');
            $('.js-contactstore-error').addClass('h-hidden');
            $('.js-preferred-store-error').removeClass('h-hidden');
        } else if (!isAvailableForInStorePickup) {
            let itemNotInStore = $('.js-contactstore-error').text().replace('#', dataStore.name);
            $('.js-contactstore-error').text(itemNotInStore);
            $('.js-ISPU').addClass('m-disabled');
            $('.js-preferred-store-error').addClass('h-hidden');
            $('.js-contactstore-error').removeClass('h-hidden');
        }
    } else if (dataStore.ID && !(isComingSoon && !isComingSoonAvailable)) {
        $('.js-ISPU').removeClass('m-disabled');
    }


    if (isWishList.length > 0) {
        $('#AddToCartModal .js-home-delivery').attr('disabled', !isShipToHome);
    }
    if (dataStore) {
        let preferredStoreError = $('.js-preferred-store').text().replace('#', dataStore.name);
        let contactStoreError = $('.js-contactstore-error').text().replace('#', dataStore.name);
        $('#AddToCartModal .js-selected-store').text(dataStore.name);
        $('#AddToCartModal .js-door-dash-form').attr('data-store-id', dataStore.ID);
        $('#AddToCartModal .js-ISPU').attr('data-store-id', dataStore.ID);
        $('#AddToCartModal .js-preferred-store').text(preferredStoreError);
        $('#AddToCartModal .js-contactstore-error').text(contactStoreError);
    }
    if (isAvailableInPrefStore && dataStore) {
        $('#AddToCartModal .js-ISPU').removeAttr('disabled');
    }

    if (!$('.js-modal-datalayer-info').data('productinfo')) {
        $('.js-modal-datalayer-info').attr('data-productinfo', JSON.stringify(productInfo));
    }
    window.dialogManager.openDialog('modal', '#AddToCartModal');
    validation.init();
}

function showAgeModal(e) {
    $(e).siblings('#ageModal').attr('id', 'showAgeModal');
    window.dialogManager.openDialog('modal', '#showAgeModal');
}

module.exports = {
    init() {
        $(document).on('click', '.js-age-esrb-confirm', (e) => {
            window.dialogManager.closeDialog();
            cookieUtil.set('esrb', true);
            let modal = $(e.target).closest('#showAgeModal');
            addToCart(modal.siblings('.js-add-to-cart-btn'));
            modal.attr('id', 'ageModal');
        });
        
        $(document).on('click', '.js-age-modal-close', (e) => {
            window.dialogManager.closeDialog('#showAgeModal');
            $(e.target).closest('#showAgeModal').attr('id', 'ageModal');
        });
        
        $(document).on('change', '.js-door-dash-postal-code', ()=>{
            let trimValue = $('.js-door-dash-postal-code').val().trim();
            $('.js-door-dash-postal-code').val(trimValue);
        });
        
        base.selectAttribute();
        base.colorAttribute();
        base.updateFreeStorePickUpAvailability();
    },
    addToCartModal() {
        $(document).on('click', '.js-add-to-cart-btn',
            (e) => {
                let $this = $(e.target);
                if (!e.isTrigger && $this.data('esrb') && !cookieUtil.get('esrb')) {
                    showAgeModal($this);
                } else {
                    addToCart($this);
                }
            });
    },
    closeModal: () => {
        $(document).on('click', '.js-home-delivery, .js-ISPU, .js-door-dash', () => {
            let $productInner = $('.js-modal-datalayer-info');
            let productInfo = JSON.parse($productInner.attr('data-productinfo'));
            let currency = $productInner.data('currency');
            if (productInfo) {
                dataLayer.addToCartDataLayerEventInit(1, productInfo, currency);
            }
            window.dialogManager.closeDialog();
            waiNotify($('#AddToCartModal').data('message'));
        });
    },
    showInStoreInventory: () => {
        $(document).on('click', '.js-btn-get-in-store-inventory', (e) => {
            const pid = $(e.currentTarget).data('search-pid');
            getModalHtmlElement();
            fillModalElement(pid, 1);
            e.stopPropagation();
        });
    },
    showZipCodeBlock: () => {
        $('body').on('click', '.js-btn-get-door-dash-inventory', () => {
            $('.js-zip-code-block').toggleClass('h-hidden');
        });
    },
    zipCodeValidation: () => {
        $('body').on('submit', '.js-door-dash-form', (e) => {
            e.preventDefault();
            $('.js-door-dash-error').hide();
            let spinner = $(e.target).spinner();
            let doorDashForm = $('.js-door-dash-form');
            let url = doorDashForm.data('action-url');
            let zip = doorDashForm.find('.js-door-dash-postal-code').val();
            let storeId = $('#AddToCartModal .js-door-dash-form').data('store-id');
            let productId = $('#AddToCartModal .js-door-dash').data('pid');
            const doorDashButton = $('.js-door-dash');
            doorDashButton.prop('disabled', true);
            spinner.start();

            let storeObject = {
                postalcode: zip,
                storeNumber: storeId,
                productId: productId
            };
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                data: storeObject,
                success(data) {
                    if (data.isValidStore) {
                        doorDashButton.attr('data-store-number', storeId);
                        doorDashButton.attr('data-zip-code', zip);
                        doorDashButton.prop('disabled', false);
                        $('.js-zip-code-block').toggleClass('h-hidden');
                        spinner.stop();
                    } else {
                        $('.js-door-dash-error').show();
                        spinner.stop();
                    }
                }
            });
        });
    },
    selectStoreWithInventory: () => {
        $('body').on('store:selected', (e, data) => {
            $(storeSelectors.emptyStoreError).addClass('h-hidden');
            $(storeSelectors.contactStoreError).addClass('h-hidden');
            $(storeSelectors.storeResultsContainer).addClass('h-hidden');
            $(storeSelectors.preferredStoreError).addClass('h-hidden');
            $('.js-ISPU').attr('data-store-id', data.storeID);
            $(storeSelectors.selectedStore).removeClass('h-hidden');
            $(storeSelectors.selectedStoreTitle).removeClass('h-hidden');
            $('.js-select-current-store').prop('disabled', true);
            if (data.storeID) {
                $('.js-ISPU').prop('disabled', false);
                $('.js-ISPU').removeClass('m-disabled');
            }

            $('.js-quantity-select').removeClass('m-disabled').prop('disabled', false);
            window.dialogManager.closeDialog();
            $('#inStoreInventoryModal, #confirmCloseStoreSelect').remove();
            $(document.body).off('click.closeConfimation');
            $('.js-selected-store').text(data.name);
            $('#AddToCartModal .js-door-dash-form').data('store-id', data.storeID);
        });
    },
    addToCartPLP() {
        $(document).on('click', 'button.js-home-delivery, button.js-ISPU, button.js-door-dash',
            function (e) {
                const $this = $(this);
                const addToCartUrl = $this.siblings('.add-to-cart-url').val();
                const tokenObject = csrfHelper.getCsrfToken();
                var form = {
                    pid: $this.data('pid'),
                    quantity: 1
                };
                if ($(e.target).hasClass('js-door-dash')) {
                    form.storeId = $(e.target).attr('data-store-number');
                    form.zipCode = $(e.target).attr('data-zip-code');
                } else if ($(e.target).hasClass('js-ISPU')) {
                    form.storeId = $('.js-ISPU').data('store-id');
                }
                $(this).trigger('updateAddToCartFormData', form);
                $.ajax({
                    url: addToCartUrl + `?${tokenObject.tokenName}=${tokenObject.token}`,
                    method: 'POST',
                    data: form,
                    success(data) {
                        if (typeof(data.message) === 'object' && data.message.quantityMessage) {
                            waiNotify(data.message.quantityMessage);
                        }
                        $('body').trigger('product:afterAddToCart', data);
                        let isWishList = $(e.target).closest('.modal-body').data('wishlist');
                        let url = $(e.target).closest('.modal-body').data('remove');
                        removeFromWishList(isWishList, url);
                    },
                    error(err) {
                        csrfHelper.checkCsrfTokenInResponse(err);
                    }
                });
            });
    }
};
