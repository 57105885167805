/**
 * Toggle filters sections
 *
 * @param {Event} event - Event object
 * @returns { undefined }
 */
function toggleAccordionItem(event) {
    const media = require('../components/utils/media');
    var isMobile = media.getBreakpoint() !== 'large';
    let $target = $(event.target);
    const $button = $target.hasClass('js-refine-accordion-button') ? $target
        : $target.parents('.js-refine-accordion-button');

    const closestRefinement = $button.parent('.js-refinement');
    const isOpen = !closestRefinement.hasClass('active');
    if (!isMobile) {
        var $allButton = $('.b-refinements-desktop .js-refine-accordion-button');
        $allButton.attr('aria-expanded', false);
        $('.b-refinements-desktop .js-refinements-container .active').removeClass('active');
        $(`#${$button.attr('aria-controls')}`).addClass('active', isOpen);
    } else {
        $(`#${$button.attr('aria-controls')}`).toggleClass('active', isOpen);
    }
    $button.attr('aria-expanded', isOpen);
    closestRefinement.toggleClass('active', isOpen);
}

module.exports = {
    toggleAccordionItem
};
