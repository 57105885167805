const processInclude = require('./util');
const dataLayer = require('./dataLayer/dataLayer');

$(document).ready(() => {
    processInclude(require('./product/tile'));
    processInclude(require('./components/collapsibleItem')); // TODO: refactor brands alphabet and remove
    processInclude(require('./product/plpAddToCart'));
    processInclude(require('./search/search'));
    processInclude(dataLayer.pushProductsInDataLayer);
});
