'use strict';

// var base = require('../product/base');
const csrfHelper = require('../csrf');

function updateAvailabilityMsg() {
    $('body').trigger('tile:updateAvailabilityMsg');
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.js-wishlistItemCardsData').data('public-view');
    var listUUID = $('.js-wishlistItemCardsData').data('uuid');
    var url = $('.js-wishlistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done((data) => {
        $('.js-wishlistItemCards').empty();
        $('body .js-wishlistItemCards').append(data);
        require('../../js/components/forms/Spinbutton')();
        updateAvailabilityMsg();
        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(() => {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

module.exports = {
    init() {
        updateAvailabilityMsg();
    },

    removeFromWishlist: function () {
        $('body').on('click', '.js-remove-from-wishlist', function (e) {
            e.preventDefault();
            var url = $(this).data('url');
            var elMyAccount = $('.js-account-wishlist-item').length;

            // If user is in my account page, call removeWishlistAccount() end point, re-render wishlist cards
            if (elMyAccount > 0) {
                $('.js-wishlist-account-card').spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'html',
                    data: {},
                    success: (html) => {
                        $('.js-wishlist-account-card>.card').remove();
                        $('.js-wishlist-account-card').append(html);
                        updateAvailabilityMsg();
                        $('.js-wishlist-account-card').spinner().stop();
                    },
                    error: () => {
                        var $elToAppend = $('.js-wishlist-account-card');
                        $elToAppend.spinner().stop();
                    }
                });
            // else user is in wishlist landing page, call removeProduct() end point, then remove this card
            } else {
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    data: {},

                    success: () => {
                        var pageNumber = $('.js-wishlistItemCardsData').data('page-number') - 1;
                        renderNewPageOfItems(pageNumber, false, '');
                    },
                    error: () => {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    addToCartFromWishlistHome: () => {
        $('body').on('click', '.js-add-to-cart-wishlist', (e) => {
            const tokenObject = csrfHelper.getCsrfToken();
            var target = $(e.target);
            var pid = target.data('pid');
            var addToCartUrl = target.data('url');
            var pidsQty = parseInt(target.closest('.b-product_tile-inner').find('.js-quantity-select').val(), 10);
            $('body').trigger('product:beforeAddToCart', this);
            var form = {
                pid: pid,
                quantity: pidsQty
            };
            if ($(this).data('option')) {
                form.options = JSON.stringify($(this).data('option'));
            }
            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl + `?${tokenObject.tokenName}=${tokenObject.token}`,
                    method: 'POST',
                    data: form,
                    success: (data) => {
                        // handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        updateAvailabilityMsg();
                        $.spinner().stop();
                    },
                    error: () => {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    addToCartFromWishlistInStore: () => {
        $('body').on('click', '.js-add-to-cart-wishlist-store', (e) => {
            const tokenObject = csrfHelper.getCsrfToken();
            var target = $(e.target);
            var pid = target.data('pid');
            var addToCartUrl = $('.add-to-cart-url').val();
            var pidsQty = parseInt(target.closest('.b-product_tile-inner').find('.js-quantity-select').val(), 10);
            var storeId = $('body').find('.js-selected-my-home-store').data('store-info').ID;
            if (!storeId) {
                $('.js-btn-get-store').trigger('click');
                return;
            }

            $('body').trigger('product:beforeAddToCart', this);
            var form = {
                pid: pid,
                quantity: pidsQty,
                storeId: storeId
            };

            if ($(this).data('option')) {
                form.options = JSON.stringify($(this).data('option'));
            }
            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl + `?${tokenObject.tokenName}=${tokenObject.token}`,
                    method: 'POST',
                    data: form,
                    success: (data) => {
                        // handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        updateAvailabilityMsg();
                        $.spinner().stop();
                    },
                    error: () => {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    moreWLItems: () => {
        $('body').on('click', '.more-wl-items', () => {
            var pageNumber = $('.js-wishlistItemCardsData').data('page-number');
            renderNewPageOfItems(pageNumber, true, '');
        });
    }
};
